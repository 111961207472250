import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Loading`}</em>{` spinners are used when retrieving data or performing slow computations, and help to notify users that loading is underway. The waiting experience is a crucial design opportunity. Although it may not be obvious what is occurring on the back-end, we can communicate clearly to reassure the user that progress is happening.`}</p>
    <p>{`It is best practice to use a loading spinner whenever the wait time is anticipated to be longer than three seconds.`}</p>
    {
      /* <p>Adopted from the <a href="https://www.ibm.com/design/language/experience/animation/elements/" target="_blank">IBM Design Language Animation Library:</a></p>
      > “The rhythmic oscillations of tape reels in motion got us thinking about a user’s waiting experience. If a user watches search results load, showing the machine at work relays a sense of efficiency. The swift and even balance of the spinning circles indicates that progress is being made.”
      ![Small loader](images/loading.gif)
      */
    }
    <h2 {...{
      "id": "size"
    }}>{`Size`}</h2>
    <p>{`Loading spinners may be scaled down if the loading experience is contextual to a certain item on the page.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "45.380434782608695%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Small loader",
        "title": "Small loader",
        "src": "/static/49aa8e837082c8e7c6e17f57edfc0978/fb070/loading-usage-2.png",
        "srcSet": ["/static/49aa8e837082c8e7c6e17f57edfc0978/d6747/loading-usage-2.png 288w", "/static/49aa8e837082c8e7c6e17f57edfc0978/09548/loading-usage-2.png 576w", "/static/49aa8e837082c8e7c6e17f57edfc0978/fb070/loading-usage-2.png 1152w", "/static/49aa8e837082c8e7c6e17f57edfc0978/c3e47/loading-usage-2.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      